type Props = {
  actionElements?: React.ReactNode
  children: React.ReactNode
  name: string
  filters?: React.ReactNode
}

export default function HomePageContentLayout({ actionElements, children, filters, name }: Props) {
  return (
    <>
      <div className='flex flex-col'>
        <div className='mt-8 flex items-start justify-between'>
          <h2 className='h1 mb-4 line-clamp-1' title={name}>
            {name}
          </h2>

          {actionElements && actionElements}
        </div>

        {filters && filters}
      </div>

      <div className='mt-8'>{children}</div>
    </>
  )
}
