import {
  Book,
  CreditCard,
  EnvelopeSimple,
  List,
  Palette,
  Star,
  Tag,
  TreeStructure,
  User,
  Users,
} from '@phosphor-icons/react'
import classnames from 'classnames'
import { useRouter } from 'next/router'

import { usePermissions } from '@hooks/use-permissions'
import NavigationItemLink from '@views/project/project-aside/components/NavigationItemLink'
import NavigationItemSkeleton from '@views/project/project-aside/components/NavigationItemSkeleton'
import { type NavigationData } from '@views/project/project-aside/data/navigation'
import { checkIsLinkActive } from '@views/project/project-aside/helpers/check-is-link-active'
import FoldersAside from '@views/surveys/FoldersAside'

type Props = {
  children: React.ReactNode
  className?: string
  type: HomepageType
}

type HomepageType = 'projects' | 'users' | 'settings'

const getNavigationData = (type: HomepageType) => {
  switch (type) {
    case 'projects':
      return Object.values(projectsNavigationData)

    case 'settings':
      return Object.values(settingsNavigationData)

    default:
      return []
  }
}

export default function HomepageLayout({ children, className, type }: Props) {
  const { pathname } = useRouter()
  const { cannot } = usePermissions()

  const navigationData = getNavigationData(type)
  const isSidePanelHidden = navigationData.length === 0

  return (
    <div
      className={classnames('-mb-16', {
        'pl-64': !isSidePanelHidden,
      })}
    >
      {!isSidePanelHidden && (
        <aside className='fixed bottom-0 left-0 top-14 z-[70] flex w-64 flex-col space-y-4 border-r bg-white p-4 shadow-aside-left'>
          <nav className='flex h-full flex-1 flex-col'>
            <div className='mb-4 space-y-1'>
              {navigationData.map((props) => {
                const { children, isDynamic, linkPathname, name, permission } = props || {}

                const [permissionAction, permissionSubject] = permission || []

                const shouldHideLink = permission
                  ? cannot(permissionAction, permissionSubject)
                  : false

                const isCategoryActive = checkIsLinkActive(pathname, { linkPathname, children })

                if (shouldHideLink) {
                  return null
                }

                if (isDynamic) {
                  return <NavigationItemSkeleton key={name} />
                }

                return <NavigationItemLink key={name} isActive={isCategoryActive} {...props} />
              })}
            </div>

            {type === 'projects' && <FoldersAside />}
          </nav>
        </aside>
      )}

      <div className={classnames('mx-auto w-full max-w-6xl px-10 pb-20', className)}>
        {children}
      </div>
    </div>
  )
}

export const projectsNavigationData: NavigationData = {
  all: {
    icon: <List />,
    name: 'All projects',
    id: 'all',
    linkPathname: `/`,
  },
  // archived: {
  //   icon: <Archive />,
  //   name: 'Archived projects',
  //   id: 'archived',
  //   linkPathname: `/projects/archived`,
  // },
  starred: {
    icon: <Star />,
    name: 'Starred',
    id: 'starred',
    linkPathname: `/projects/starred`,
  },
  personal: {
    icon: <User />,
    name: 'My projects',
    id: 'personal',
    linkPathname: '/projects/personal',
  },
}

export const settingsNavigationData: NavigationData = {
  branding: {
    name: 'Branding',
    linkPathname: '/organisation/branding',
    id: 'branding',
    permission: ['read', 'Organisation'],
    icon: <Palette />,
  },
  members: {
    name: 'Members',
    linkPathname: '/organisation/members',
    id: 'members',
    permission: ['read', 'Organisation'],
    icon: <Users />,
  },
  workflows: {
    name: 'Workflows',
    linkPathname: '/organisation/workflows',
    id: 'workflows',
    permission: ['read', 'Workflow'],
    icon: <TreeStructure />,
  },
  payments: {
    name: 'Payments',
    linkPathname: '/organisation/payments',
    id: 'payments',
    permission: ['read', 'PaymentMethod'],
    icon: <CreditCard />,
  },
  notifications: {
    name: 'Notifications',
    linkPathname: '/organisation/notifications',
    id: 'notifications',
    permission: ['read', 'EmailTemplate'],
    icon: <EnvelopeSimple />,
  },
  labels: {
    name: 'Labels',
    linkPathname: '/organisation/labels',
    id: 'labels',
    permission: ['update', 'Project'],
    icon: <Tag />,
  },
  questionLibrary: {
    name: 'Question Library',
    linkPathname: '/organisation/question-library',
    id: 'question-library',
    permission: ['read', 'Definition'],
    icon: <Book />,
  },
}
