import { useEffect } from 'react'

import classnames from 'classnames'
import { Dialog } from 'primereact/dialog'

type Props = {
  children: React.ReactNode
  className?: string
  closable?: boolean
  contentClassName?: string
  description?: string | React.ReactNode
  dismissableMask?: boolean
  footer?: React.ReactNode
  headerClassName?: string
  headerContentClassName?: string
  isVisible: boolean
  title: string | React.ReactNode
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | '3xl'
  onModalClose: () => void
}

export default function Modal({
  children,
  className,
  closable = true,
  contentClassName,
  description,
  dismissableMask = true,
  footer,
  headerClassName,
  headerContentClassName,
  isVisible,
  onModalClose,
  size,
  title,
}: Props) {
  useEffect(() => () => document.body.classList.remove('p-overflow-hidden'), [])

  return (
    <Dialog
      blockScroll
      className={classnames(
        'w-full',
        {
          ['max-w-sm']: size === 'sm',
          ['max-w-md']: size === 'md',
          ['max-w-lg']: size === 'lg',
          ['max-w-2xl']: size === 'xl',
          ['max-w-5xl']: size === 'xxl',
          ['max-w-7xl']: size === '3xl',
        },
        className,
      )}
      closable={closable}
      contentClassName={classnames(
        'space-y-4 flex flex-col',
        !footer && 'rounded-b-md',
        contentClassName,
      )}
      dismissableMask={dismissableMask}
      draggable={false}
      focusOnShow={false}
      footer={footer}
      header={
        <div className={headerContentClassName}>
          {title}
          {description && (
            <span className='mt-2 block text-sm font-normal leading-normal text-gray-600'>
              {description}
            </span>
          )}
        </div>
      }
      headerClassName={classnames('relative', headerClassName)}
      resizable={false}
      visible={isVisible}
      onHide={() => onModalClose()}
    >
      {children}
    </Dialog>
  )
}
