type Props = {
  children: React.ReactNode
  className?: string
  name: string
  isHidden?: boolean
}

export function Input({ children, className, isHidden, name }: Props) {
  return (
    <div className={className}>
      {!isHidden && <span className='mb-1 block text-base font-medium text-gray-900'>{name}</span>}
      {children}
    </div>
  )
}
