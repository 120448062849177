// idealy this should be handled by external service or backend implementation
import { checkIsQrowdsyOrganization } from '@helpers/check-is-qrowdsy-organization'
import { useUser } from '@hooks/use-user'

type Flags = {
  showQrowdsyProductOwners: boolean
  showResearcherTypeLinkGeneration: boolean
}

const QROWDSY_FLAGS: Flags = {
  showQrowdsyProductOwners: true,
  showResearcherTypeLinkGeneration: true,
}

const DEFAULT_FLAGS: Flags = {
  showQrowdsyProductOwners: false,
  showResearcherTypeLinkGeneration: false,
}

export function useFlags() {
  const { user } = useUser()
  const isQrowdsy = checkIsQrowdsyOrganization(user.organisationId)

  return isQrowdsy ? QROWDSY_FLAGS : DEFAULT_FLAGS
}
