import type { ZodType } from 'zod'

export function zodValidate(data: unknown, schema: ZodType) {
  const safeParsed = schema.safeParse(data)

  // @ts-ignore
  if (safeParsed?.error) {
    // @ts-ignore
    const formatedErrors = safeParsed?.error.format()

    for (const key in formatedErrors) {
      const lastError = formatedErrors[key]?._errors?.[0]
      formatedErrors[key] = lastError
    }

    return { success: false, errors: formatedErrors }
  }

  return { success: true, errors: null }
}
