import React from 'react'

import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

import type { NavigationItem } from '@views/project/project-aside//data/navigation'
import NavigationSub from '@views/project/project-aside/components/NavigationSub'
import { getNavigationLink } from '@views/project/project-aside/helpers/get-navigation-link'

type Props = {
  onModalOpen?: () => void
  isActive: boolean
  isLoading?: boolean
} & NavigationItem

export default function NavigationItemLink({ isActive, isLoading, onModalOpen, ...props }: Props) {
  const { query } = useRouter()
  const { children, linkPathname, linkQuery, name } = props

  const icon = props.icon ? React.cloneElement(props.icon, { size: 24 }) : null

  return (
    <div>
      <Link
        className={classNames(
          'flex h-10 w-full items-center space-x-4 rounded-md px-4 py-2 text-sm font-medium transition ease-in-out hover:bg-gray-100',
          {
            'bg-gray-100 font-semibold text-branding': isActive,
          },
        )}
        href={getNavigationLink(linkPathname, { ...query, ...linkQuery })}
      >
        {icon}
        <span>{name}</span>
      </Link>

      {isActive && children && (
        <NavigationSub isLoading={isLoading} openModal={onModalOpen} {...props} />
      )}
    </div>
  )
}
